import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  overVid = true;

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    window.onscroll = e => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop >= 600) {
        this.overVid = false;
      } else {
        this.overVid = true;
      }
    };
  }

  scroll(id) {
    window.scrollTo({
      top: document.getElementById(id).getBoundingClientRect().top + window.pageYOffset - 60,
      behavior: 'smooth',
    });
  }

}
