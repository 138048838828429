import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  imageUrlArray = [
    '../../assets/images/ss1.jpg',
    '../../assets/images/ss2.jpg',
    '../../assets/images/ss3.jpg',
    '../../assets/images/ss4.jpg',
    '../../assets/images/ss5.jpg',
  ];

  constructor() { }

  ngOnInit() {
  }

  scroll(id) {
    window.scrollTo({
      top: document.getElementById(id).getBoundingClientRect().top + window.pageYOffset - 60,
      behavior: 'smooth',
    });
  }

}
